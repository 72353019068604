import React, { useEffect, useState } from "react";
import "../../Style/main.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Dropdown from "../Dropdown.js/Dropdown";
import axios from "axios";
import VideoList from "../VideoList/VideoList";
import Spinner from "react-bootstrap/Spinner";

const postsPerPage = 12;

const Search = () => {
  const [searchData, setSearchData] = useState("");
  const [videoData, setVideoData] = useState([]);

  const [loader, setLoader] = useState(false);
  const [eventData, setEventData] = useState([]);
  const [speakersData, setSpeakersData] = useState([]);
  const [technologyData, setTechnologyData] = useState([]);
  const [typeData, setTypeData] = useState([]);

  const [selectedTechStatus, setSelectedTechStatus] = useState("");
  const [selectedTypeStatus, setSelectedTypeStatus] = useState("");
  const [selectedEventStatus, setSelectedEventStatus] = useState("");
  const [selectedSpeakerStatus, setSelectedSpeakerStatus] = useState("");

  const [videoError, setVideoError] = useState("");
  
const [count, setCount] = useState(0);
  const [handlecount, setHandleCount] = useState(0);
  
  const [next, setNext] = useState(postsPerPage);
  var myurl = "/api/getTalks.php?";

  //for query results
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

   if (
      params.get("query") ||
      params.get("technologies") ||
      params.get("type") ||
      params.get("event") ||
      params.get("speakers")
    ) {
      setSearchData(params.get("query"));
      setSelectedTechStatus(params.get("technologies"));
      setSelectedTypeStatus(params.get("type"));
      setSelectedEventStatus(params.get("event"));
      setSelectedSpeakerStatus(params.get("speakers"));
      getTalks(
        params.get("query"),
        params.get("event"),
        params.get("technologies"),
        params.get("type"),
        params.get("speakers")
      );
    } else {
      console.log("first time");
    }
  }, []);

  //for filters results
  useEffect(() => {
    axios
      .get("/api/filters.json")
      .then((response) => {
        setEventData(Object.values(response.data.event));
        setSpeakersData(Object.values(response.data.speakers));
        setTechnologyData(Object.values(response.data.technologies));
        setTypeData(Object.values(response.data.type));
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }, []);

  //   useEffect(() => {
  //     setLoader(false);
  //     onSearchButton();
  //   }, []);

  const getTalks = async (
    searchdata,
    eventdata,
    technologydata,
    typedata,
    speakersdata,
    countData
  ) => {
    if (searchdata) {
      myurl += "query=" + searchdata;
    }
    if (eventdata) {
      myurl += "&event=" + eventdata;
    }
    if (technologydata) {
      myurl += "&technologies=" + technologydata;
    }
    if (typedata) {
      myurl += "&type=" + typedata;
    }
    if (speakersdata) {
      myurl += "&speakers=" + speakersdata;
    }

     if (countData) {
      myurl += "&from=" + countData
    }
    await axios
      .get(
        myurl
      )
      .then((response) => {
        if (response.data.results.length != 0) {
           setLoader(false);

          setVideoData([...videoData, ...response.data.results])
        } else {
          setLoader(false);
          setVideoError("Video Lecture Not found");
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  const onSearchButton = async (e) => {
    e.preventDefault();
    var appurl = "";
    // appurl =
    //   window.location.protocol +
    //   "//" +
    //   window.location.host +
    //   window.location.pathname;

    // let queries = appurl.split("/")[3];
    // if(queries.includes("&")){
    //     queries = appurl.split("/")[3].split("&");
    // }
    // console.log("queries", queries);

    if (searchData) {
      appurl += "query=" + searchData;
    }
    if (selectedEventStatus) {
      appurl += "&event=" + selectedEventStatus;
    }
    if (selectedTechStatus) {
      appurl += "&technologies=" + selectedTechStatus;
    }
    if (selectedTypeStatus) {
      appurl += "&type=" + selectedTypeStatus;
    }
    if (selectedSpeakerStatus) {
      appurl += "&speakers=" + selectedSpeakerStatus;
    }

    // window.history.replaceState({}, "", appurl);
    window.history.replaceState(null, null, "?" + appurl);
    // return
    setVideoData([]);
    setNext(12);
    setLoader(true);
    getTalks(
      searchData,
      selectedEventStatus,
      selectedTechStatus,
      selectedTypeStatus,
      selectedSpeakerStatus,
       handlecount
    );
     videoData.length = 0
    setHandleCount(0)
    setCount(handlecount + 25)
  };

  const handleShowMorePosts = () => {
    setNext(next + postsPerPage);
    setCount(count + 25)
    getTalks(
      searchData,
      selectedEventStatus,
      selectedTechStatus,
      selectedTypeStatus,
      selectedSpeakerStatus,
      count
    );

  };

   const handleClear = () => {
    setSearchData('')
    setSelectedEventStatus('')
    setSelectedSpeakerStatus('')
    setSelectedTechStatus('')
    setSelectedTypeStatus('')
  }
    
  return (
    <>
      <div className="filter_form">
        <Form onSubmit={onSearchButton}>
    
        <div className="row">
           <div className="col-md-12">
              <Button
                variant="primary"
                className="clear_btn"
                onClick={() => handleClear()}
              >
                Clear Filter
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">
              <Form.Group>
                <Form.Label>Write here to search your VIDEO LECTURE</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => {
                    setSearchData(e.target.value);
                     setCount(0);
                  }}
                  value={searchData}
                />
              </Form.Group>
            </div>
            <div className="col-md-4">
              <Form.Group>
                <Form.Label>Technologies</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => {
                    setSelectedTechStatus(e.target.value);
                     setCount(0)
                  }}
                  value={selectedTechStatus}
                >
                  {technologyData.map((s, key) => (
                    <Dropdown s={s} selectedStatus={selectedTechStatus} />
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Form.Group>
                <Form.Label>Type</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => {
                    setSelectedTypeStatus(e.target.value);
                     setCount(0)
                  }}
                  value={selectedTypeStatus}
                >
                  {typeData.map((s, key) => (
                    <Dropdown s={s} selectedStatus={selectedTypeStatus} />
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
            <div className="col-md-4">
              <Form.Group>
                <Form.Label>Event</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => {
                    setSelectedEventStatus(e.target.value);
                     setCount(0)
                  }}
                  value={selectedEventStatus}
                >
                  {eventData.map((s, key) => (
                    <Dropdown s={s} selectedStatus={selectedEventStatus} />
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
            <div className="col-md-4">
              <Form.Group>
                <Form.Label>Speaker</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => {
                    setSelectedSpeakerStatus(e.target.value);
                     setCount(0)
                  }}
                  value={selectedSpeakerStatus}
                >
                  {speakersData.map((s, key) => (
                    <Dropdown s={s} selectedStatus={selectedSpeakerStatus} />
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Button variant="primary" className="green_btn" type="submit">
                Search
              </Button>
            </div>
          </div>
        </Form>
      </div>

      {loader == false ? (
        videoData.length != 0 ? (
          <>
            <VideoList data={videoData} next={next} />
            {next < videoData?.length && (
              <button onClick={handleShowMorePosts} className="border_btn">
                Load more
              </button>
            )}
          </>
        ) : (
          <p className="error">{videoError}</p>
        )
      ) : (
        <div class="loader">
          <Spinner animation="border" variant="light" />
        </div>
      )}
    </>
  );
};
export default Search;
